import { StepSyncProgress, IStepSyncProgressOptions, IStepSyncProgressJSON } from 'o365.pwa.modules.client.steps.StepSyncProgress.ts';
import type { IUIFriendlyMessageJSON, UIFriendlyMessage } from 'o365.pwa.modules.UIFriendlyMessage.ts';

export interface IDataObjectProgressJSON extends IStepSyncProgressJSON {
    startedGeneratingOfflineData: boolean;
    errorsGeneratingOfflineData: boolean;
    completedGeneratingOfflineData: boolean;
    startedRetrievingRowCount: boolean;
    errorsRetrievingRowCount: boolean;
    completedRetrievingRowCount: boolean;
    startedRetrievingFiles: boolean;
    errorsRetrievingFiles: boolean;
    completedRetrievingFiles: boolean;
    filesToSync: number;
    filesCompleted: number;
    filesStarted: number;
    filesFailed: number;
    recordsToSync: number;
    recordsStarted: number;
    recordsCompleted: number;
    recordsFailed: number;
    uiFriendlyMessages: Array<IUIFriendlyMessageJSON>
    filesSynced: number;
    onlineSyncStarted: boolean;
    onlineSyncFailed: boolean;
    onlineSyncCompleted: boolean;
    onlineFileSyncStarted: boolean;
    onlineFileSyncFailed: boolean;
    onlineFileSyncCompleted: boolean;
    recordsSynced: number;
}

export interface IDataObjectProgressOptions extends IStepSyncProgressOptions {
    startedGeneratingOfflineData: boolean;
    errorsGeneratingOfflineData: boolean;
    completedGeneratingOfflineData: boolean;
    startedRetrievingRowCount: boolean;
    errorsRetrievingRowCount: boolean;
    completedRetrievingRowCount: boolean;
    startedRetrievingFiles: boolean;
    errorsRetrievingFiles: boolean;
    completedRetrievingFiles: boolean;
    filesToSync: number;
    filesCompleted: number;
    filesStarted: number;
    filesFailed: number;
    recordsToSync?: number;
    recordsStarted?: number;
    recordsCompleted?: number;
    recordsFailed?: number;
    uiFriendlyMessages: Array<UIFriendlyMessage>
    filesSynced: number;
    onlineSyncStarted: boolean;
    onlineSyncFailed: boolean;
    onlineSyncCompleted: boolean;
    onlineFileSyncStarted: boolean;
    onlineFileSyncFailed: boolean;
    onlineFileSyncCompleted: boolean;
    recordsSynced: number;
    startedTruncating: boolean;
    errorsTruncating: boolean;
    completedTruncating: boolean;
}

export class DataObjectProgress extends StepSyncProgress {
    public startedGeneratingOfflineData: boolean = false;
    public errorsGeneratingOfflineData: boolean = false;
    public completedGeneratingOfflineData: boolean = false;
    public startedRetrievingRowCount: boolean = false;
    public errorsRetrievingRowCount: boolean = false;
    public completedRetrievingRowCount: boolean = false;
    public startedRetrievingFiles: boolean = false;
    public errorsRetrievingFiles: boolean = false;
    public completedRetrievingFiles: boolean = false;
    public filesToSync: number = 0;
    public filesCompleted: number = 0;
    public filesStarted: number = 0;
    public filesFailed: number = 0;
    public recordsToSync: number = 0;
    public recordsStarted: number = 0;
    public recordsCompleted: number = 0;
    public recordsFailed: number = 0;
    public filesSynced: number = 0;
    public recordsSynced: number = 0;
    public uiFriendlyMessages: Array<UIFriendlyMessage> = new Array();
    public onlineSyncStarted: boolean = false;
    public onlineSyncFailed: boolean = false;
    public onlineSyncCompleted: boolean = false;
    public onlineFileSyncStarted: boolean = false;
    public onlineFileSyncFailed: boolean = false;
    public onlineFileSyncCompleted: boolean = false;
    public startedTruncating: boolean = false;
    public errorsTruncating: boolean = false;
    public completedTruncating: boolean = false;

    private get progressStatusTotal(): number {
        return this.recordsToSync + this.filesToSync;
    }

    private get progressStatusSuccessTotal(): number {
        return this.recordsStarted + this.recordsCompleted + this.filesCompleted + this.recordsSynced + this.filesSynced;
    }

    private get progressStatusErrorTotal(): number {
        return this.recordsFailed + this.filesFailed;
    }

    public get progressStatusPending(): number {
        if (
            this.errorsGeneratingOfflineData ||
            this.errorsRetrievingRowCount ||
            this.onlineSyncFailed ||
            this.onlineFileSyncFailed
        ) {
            return 0;
        }

        if (
            (this.startedGeneratingOfflineData && !this.completedGeneratingOfflineData) ||
            (this.startedRetrievingRowCount && !this.completedRetrievingRowCount)
        ) {
            return 100;
        }

        const total = this.progressStatusTotal;

        if (total === 0) {
            return 0;
        }

        const success = this.progressStatusSuccessTotal;
        const error = this.progressStatusErrorTotal;

        let result = (total - success - error) / total * 100;
        
        return result;
    }

    public get progressStatusSuccess(): number {
        const total = this.progressStatusTotal;

        if (
            this.errorsGeneratingOfflineData ||
            this.errorsRetrievingRowCount ||
            this.onlineSyncFailed ||
            this.onlineFileSyncFailed ||
            (this.startedGeneratingOfflineData && !this.completedGeneratingOfflineData) ||
            (this.startedRetrievingRowCount && !this.completedRetrievingRowCount)
        ) {
            return 0;
        }

        if (total === 0) {
            if (
                (this.startedRetrievingRowCount && this.completedRetrievingRowCount) || 
                (this.onlineSyncStarted && this.onlineSyncCompleted) ||
                (this.onlineFileSyncStarted && this.onlineFileSyncCompleted)
            ) { return 100; }

            return 0;
        }

        const success = this.progressStatusSuccessTotal;

        let result = success / total * 100;

        return result;
    }

    public get progressStatusError(): number {
        if (
            this.errorsGeneratingOfflineData ||
            this.errorsRetrievingRowCount ||
            this.onlineSyncFailed ||
            this.onlineFileSyncFailed
        ) {
            return 100;
        }

        const total = this.progressStatusTotal;

        if (total === 0) {
            return 0;
        }

        const error = this.progressStatusErrorTotal;

        const result = error / total * 100;
        
        return result;
    }

    constructor(options: IDataObjectProgressOptions) {
        super(options);
        options.syncType && (this.syncType = options.syncType);
        options.startedGeneratingOfflineData && (this.startedGeneratingOfflineData = options.startedGeneratingOfflineData);
        options.errorsGeneratingOfflineData && (this.errorsGeneratingOfflineData = options.errorsGeneratingOfflineData);
        options.completedGeneratingOfflineData && (this.completedGeneratingOfflineData = options.completedGeneratingOfflineData);
        options.startedRetrievingRowCount && (this.startedRetrievingRowCount = options.startedRetrievingRowCount);
        options.errorsRetrievingRowCount && (this.errorsRetrievingRowCount = options.errorsRetrievingRowCount);
        options.completedRetrievingRowCount && (this.completedRetrievingRowCount = options.completedRetrievingRowCount);
        options.startedRetrievingFiles && (this.startedRetrievingFiles = options.startedRetrievingFiles);
        options.errorsRetrievingFiles && (this.errorsRetrievingFiles = options.errorsRetrievingFiles);
        options.completedRetrievingFiles && (this.completedRetrievingFiles = options.completedRetrievingFiles);
        options.filesToSync && (this.filesToSync = options.filesToSync);
        options.filesCompleted && (this.filesCompleted = options.filesCompleted);
        options.filesStarted && (this.filesStarted = options.filesStarted);
        options.filesFailed && (this.filesFailed = options.filesFailed);
        options.recordsToSync && (this.recordsToSync = options.recordsToSync);
        options.recordsStarted && (this.recordsStarted = options.recordsStarted);
        options.recordsCompleted && (this.recordsCompleted = options.recordsCompleted);
        options.recordsFailed && (this.recordsFailed = options.recordsFailed);
        options.uiFriendlyMessages && (this.uiFriendlyMessages = options.uiFriendlyMessages);
        options.filesSynced && (this.filesSynced = options.filesSynced);
        options.onlineSyncStarted && (this.onlineSyncStarted = options.onlineSyncStarted);
        options.onlineSyncFailed && (this.onlineSyncFailed = options.onlineSyncFailed);
        options.onlineSyncCompleted && (this.onlineSyncCompleted = options.onlineSyncCompleted);
        options.onlineFileSyncStarted && (this.onlineFileSyncStarted = options.onlineFileSyncStarted);
        options.onlineFileSyncFailed && (this.onlineFileSyncFailed = options.onlineFileSyncFailed);
        options.onlineFileSyncCompleted && (this.onlineFileSyncCompleted = options.onlineFileSyncCompleted);
        options.recordsSynced && (this.recordsSynced = options.recordsSynced);
    }

    public toJSON(): IDataObjectProgressJSON {
        return Object.assign(super.toJSON(), this);
    }
}
